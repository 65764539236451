import { render, staticRenderFns } from "./DroneLotPartModal.vue?vue&type=template&id=08afbee3"
import script from "./DroneLotPartModal.vue?vue&type=script&lang=js"
export * from "./DroneLotPartModal.vue?vue&type=script&lang=js"
import style0 from "./DroneLotPartModal.vue?vue&type=style&index=0&id=08afbee3&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@i18n/drone/part.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdrone%2FDroneLotPartModal.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@i18n/drone/drone_lot.th.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdrone%2FDroneLotPartModal.vue&locale=th&lang=yaml&external"
if (typeof block1 === 'function') block1(component)
import block2 from "./DroneLotPartModal.vue?vue&type=custom&index=2&blockType=i18n&locale=th&lang=yaml"
if (typeof block2 === 'function') block2(component)

export default component.exports