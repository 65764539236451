<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n src="@i18n/drone/drone_lot.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
lot_part.title : "เพิ่มอะไหล่"
lot_part.ok : "เพิ่ม"
lot_part.title.update : "แก้ไขข้อมูลอะไหล่"
lot_part.ok.update : "@:common.save"

lot_part.field.part : "อะไหล่"
lot_part.field.type : "ประเภท"
lot_part.field.type.help : "<b>อะไหล่ตั้งต้น</b> - เป็นชิ้นส่วนที่มาพร้อมกับตัวเครื่อง<br /> <b>อะไหล่เสริม</b> - เป็นอะไหล่ที่สามารถติดตั้งเพิ่มเติม เช่นอะไหล่สำรอง หรือ อุปกรณ์เสริม เป็นต้น"
lot_part.field.total.help : "ใน Drone 1 ตัวมีชิ้นส่วนนี้ได้กี่ชิ้น (ไม่ระบุ หรือ ค่า 0 = ไม่จำกัด)"
lot_part.field.remark.placeholder : "หมายเหตุอะไหล่ใน Drone ชิ้นนี้ เช่น ใช้สำรองทดแทนอีกรุ่น เป็นต้น"

display.compare.model : "ค่าที่ระบุใน Drone Model"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="modalTitle"
		:ok-text="modalOkLabel"
		:width="500"
		:confirm-loading="loading"
		:cancel-button-props="{ props: { disabled: loading } }"
		@ok="handleOk"
		@cancel="handleCancel">
		<a-form layout='vertical' :form="formObj">
			<a-form-item  v-show="!isUpdate" :label="$t('lot_part.field.part')" >
				<PartSelect
					v-decorator="[
						'part_id' ,
						{
							rules: [
							{ required: true, message: $tt('validate.required','lot_part.field.part') },
							],
						},
					]"
					:excluded-part-id-list="currentPartIdList"
					@change="handlePartChange"/>
			</a-form-item>

			<PartLiteCard v-show="selectedPart != undefined" :part="selectedPart"
				class="modal-part-card" link-target="_blank"
				:bordered="true" size="small"/>

			<a-form-item v-show="canManageInitial" :label="$t('lot_part.field.type')">
				<span slot="extra" v-html="$t('lot_part.field.type.help')"></span>
				<a-radio-group
					v-decorator="[
						'type' ,
						{
							rules: [
							{ required: true ,message: $tt('validate.required','lot_part.field.type') },
							] ,
							'initialValue': 'initial',
						},
					]">
					<a-radio value="initial">{{$tenum('drone_part_type','initial')}}</a-radio>
					<a-radio value="optional">{{$tenum('drone_part_type','optional')}}</a-radio>
				</a-radio-group>
				<MyTooltip v-if="isShowModelPartInfo" :title="$t('display.compare.model')">
					<span  class="ant-form-text cursor-pointer compare-model-data">
						( {{$tenum('drone_part_type',lotPart.type)}} )
					</span>
				</MyTooltip>
			</a-form-item>

			<a-form-item v-show="!canManageInitial" :label="$t('lot_part.field.type')">
				<span class="ant-form-text">
					{{displayPartType}}
				</span>
			</a-form-item>

			<a-form-item :label="$t('lot_part.field.total')"
				:extra="$t('lot_part.field.total.help')">
				<a-input-number
						v-decorator="['total']"
						:min="0" :step="1"
						:precision="0"
						class="myinput-number"
						length="30">
				</a-input-number>
				<span class="ant-form-text">
					{{$t('common.part.unit')}}
				</span>
				<MyTooltip v-if="isShowModelPartInfo" :title="$t('display.compare.model')">
					<span  class="ant-form-text cursor-pointer compare-model-data">
						( {{$tc_my('part.number.display',modelPart.total)}} )
					</span>
				</MyTooltip>
			</a-form-item>
			<a-form-item :label="$t('lot_part.field.remark')">
				<a-textarea
					v-decorator="['remark']"
					:auto-size="{ minRows: 3,maxRows: 8 }"
					:placeholder="$t('lot_part.field.remark.placeholder')">
				</a-textarea>
			</a-form-item>


		</a-form>
	</a-modal>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import PartSelect from '@components/part/PartSelect.vue'
import PartLiteCard from '@components/part/PartLiteCard.vue'
import MyTooltip from "@components/common/MyTooltip.vue"
import { updateFieldsValue } from '@utils/formUtil'
import {InputNumber,Radio} from "ant-design-vue"
import { mapGetters } from 'vuex'

export default {
	components : {
		PartSelect , PartLiteCard,
		"a-radio" : Radio, "a-radio-group" : Radio.Group,
		"a-input-number" : InputNumber , MyTooltip
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {
		lot : {
			type : null,
			default : () => []
		} ,
		visible : {
			type : Boolean ,
			default : false,
		} ,
		lotPart : {
			type : null,
			default : () => []
		} ,
		modelPart : {
			type : null,
			default : ()=>[]
		} ,
		currentPartIdList : {
			type : Array,
			default : () => []
		} ,
		canManageInitial : {
			type : Boolean,
			default : false,
		} ,
		loading : {
			type : Boolean,
			default : false
		} ,
		formError : {
			type : null,
			default : null
		} ,
	}	,
	data() {
		return {
			selectedPart : undefined,
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		isUpdate() {
			return this.lotPart.part_id
		} ,
		displayPartType() {
			if (this.isUpdate) {
				return this.$tenum('drone_part_type',this.lotPart.type)
			} else {
				return this.$tenum('drone_part_type','optional')
			}
		} ,
		isShowModelPartInfo() {
			return this.isUpdate && this.lotPart.mode != 'custom' && this.modelPart.part_id
		},
		modalTitle() {
			if (this.isUpdate) {
				return this.$t('lot_part.title.update')
			} else {
				return this.$t('lot_part.title')
			}
		} ,
		modalOkLabel() {
			if (this.isUpdate) {
				return this.$t('lot_part.ok.update')
			} else {
				return this.$t('lot_part.ok')
			}
		}
	} ,
	watch: {
		visible(newVal) {
			if (!newVal) {
				this.formObj.resetFields()
				this.selectedPart = undefined;
			}
		} ,
		formError() {
			this.formSubmitErrors(this.formError)
		} ,
		lotPart : {
			handler() {
				this.updateForm()
				// this.$nextTick(()=>{
				// 	this.updateForm()
				// })

			} ,
			deep : true
		}
	} ,
	methods : {
		updateForm() {
			updateFieldsValue(this.formObj,this.lotPart)
			if (this.isUpdate) {
				this.selectedPart = this.lotPart.part
			}
		} ,
		handleOk() {
			this.formObj.validateFields((err, values) => {
				if (!err) {
					const submit = {...values};
					if (this.isUpdate) {
						submit.part_id = this.lotPart.part_id
						submit.mode = this.lotPart.mode == 'custom' ? 'custom' : 'change'
					} else {
						submit.mode = 'custom'
						if (!this.canManageInitial)
							submit.type = 'optional'
					}
					submit.drone_lot_id = this.lot.id
					this.$emit('submit',submit)
				} else {
					this.displayValidateErrorMessage()
				}
			});
		} ,
		handleCancel() {
			if (this.loading)
				return;
			this.$emit('close')
		} ,
		handlePartChange(partId) {
			if (!partId)
				this.selectedPart = undefined;
			else
				this.selectedPart = this.getPartById(partId)
		}
	}
}
</script>
<style lang="less">
.modal-part-card {
	margin-top : -16px;
	margin-bottom : 16px;
	background-color : @blue-2;
}
.compare-model-data {
	color : @warning-color;
	font-weight: 600;
}
</style>
